import React from "react";
import "./App.css";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "i18n/en.json";
import WorldPoverty from "views/WorldPovertyClock";
import { WorldPovertyClockContextProvider } from "context/worldPoverty";
import ErrorBoundary from "components/ErrorBoundary";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(detector) // detect user language
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: true
        },
        resources: {
            en: {
                translation: en
            }
        }
    });

const App = () => {

    return (
        <ErrorBoundary>
            <WorldPovertyClockContextProvider>
                <WorldPoverty />
            </WorldPovertyClockContextProvider>
        </ErrorBoundary>
    );
};

export default App;
